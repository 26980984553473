


@function multipleBoxShadow($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }
  @return unquote($value)
}

$shadows-small:  multipleBoxShadow(700);
$shadows-medium: multipleBoxShadow(200);
$shadows-big:    multipleBoxShadow(100);


@keyframes moveStar {
  from {
    transform: translateY(0px)
  }
  to {
    transform: translateY(-2000px)
  }
}

.root {
  background: radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%);
  overflow: hidden;
}

.smallStars{
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: moveStar 100s linear infinite;

  &:after{
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }

}

.mediumStars{
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: moveStar 100s linear infinite;
  &:after{
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}

.bigStars{
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: moveStar 150s linear infinite;
  &:after{
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

